import React, { useEffect } from 'react';
import './style.scss';

export default function BackgroundAnimation() {


    useEffect(() => {
        const contentSphere = document.getElementsByClassName('contenttrigger')[0];
        let curX = 0;
        let curY = 0;
        let tgX = 0;
        let tgY = 0;

        function move() {
            curX += (tgX - curX) / 20;
            curY += (tgY - curY) / 20;
            contentSphere.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
            requestAnimationFrame(() => {
                move();
            });
        }

        window.addEventListener('mousemove', (event) => {
            tgX = event.clientX;
            tgY = event.clientY;
        });

        move();
    }, []);

    return (
        <React.Fragment>
            <div className="background-animation">
                <div class="gradient-bg">
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur in="SrcGfx" stdDeviation="10" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result="goo" />
                                <feBlend in="SrcGfx" in2="goo" />
                            </filter>
                        </defs>
                    </svg>
                    <div class="gradients-container">
                        <div class="g1"></div>
                        <div class="g2"></div>
                        <div class="g3"></div>
                        <div class="g4"></div>
                        <div class="g5"></div>
                        <div class="contenttrigger"></div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}