import React, { useEffect } from 'react';
import './style.scss';

export default function HeroChangingText() {
    let herosTextContainer;
    let herosEntries;
    let heroEntriesLineHeight;
    let currentHeroEntry = 0;
    const delay = ms => new Promise(res => setTimeout(res, ms));


    useEffect(() => {
        herosTextContainer = document.getElementById("herochangingtext-container");
        herosEntries = document.getElementById("herochangingtext-container").getElementsByClassName("hero-texts--single");
        heroEntriesLineHeight = getComputedStyle(herosTextContainer.getElementsByClassName("hero-texts--single")[0]).lineHeight;
        changeTexts();
    }, []);

        const changeTexts = async () => {

            for (let currentHeroEntry = 0; currentHeroEntry < herosEntries.length; currentHeroEntry++) {
                for (let j = 0; j < herosEntries.length; j++) {
                    herosEntries[j].style.opacity = 0;
                    herosEntries[j].style.height = 0;
                }
                herosEntries[currentHeroEntry].style.height = "auto";
                herosEntries[currentHeroEntry].style.opacity = 1;
                if(currentHeroEntry >= herosEntries.length - 1) {
                    currentHeroEntry = -1;

                }
                await delay(1500);
            }
        };

    return (
        <React.Fragment>
            <div id="herochangingtext-container">
                <div className="hero-texts">
                    <div className="hero-texts--single">can</div>
                    <div className="hero-texts--single">should</div>
                    <div className="hero-texts--single">wants to</div>
                    <div className="hero-texts--single">needs to</div>
                    <div className="hero-texts--single">has to</div>
                    <div className="hero-texts--single">must</div>
                </div>
            </div>
        </React.Fragment>
    );
}