import React from "react";
import './style.scss';

export default function Experiences() {
    return (
        <React.Fragment>
            <div className="experiences">
                <h1>experiences</h1>
                <p>
                    from corporate to startup to pro bono - I've always contributed my best to drive our team towards shared goals. I've learned the
                    importance of effective communication, teamwork, and collaboration and strive to identify and resolve issues together.
                </p>
                <p>
                    Reliability, respect and honest communication are some of the factors that <div className="blackbox">make or break a project.</div>
                </p>
                <br />
                <br />
                <br />
                <div className="experiences-content">
                    <div className="experiences-content-customers">

                        <h3>customers</h3>
                        <ul>
                            <li><a href="https://cariad.technology/" target="_blank" alt="CARIAD SE">CARIAD SE</a></li>
                            <li><a href="https://www.volkswagen.de/" target="_blank" alt="Volkswagen">Volkswagen AG</a></li>
                            <li><a href="https://www.mercedes-benz.de/passengercars/buy/new-car.html" target="_blank" alt="Mercedes Benz New Car Online Sales">Mercedes Benz Digital Commerce Platform NCOS</a></li>
                            <li><a href="https://www.stihl.de/" target="_blank" alt="Stihl AG & CoKG">Andreas Stihl AG & Company KG</a></li>
                            <li><a href="https://cariad.technology/" target="_blank" alt="CARIAD SE">Bechtle AG</a></li>
                            <li><a href="https://www.trumpf.com/" target="_blank" alt="Trumpf">Trumpf SE + Co. KG</a></li>
                            <li><a href="https://www.zeiss.com/vision-care/de/highlights/neue-produkte/smartlife-young-brillenglaeser.html" target="_blank" alt="SmartLife Young Brillengläser">Carl Zeiss AG</a></li>
                            <li><a href="https://www.selgros.de/" target="_blank" alt="Selgros AG">Selgros</a></li>
                            <li><a href="https://www.transgourmet.de/" target="_blank" alt="Transgourmet Deutschland GmbH & Co. OHG">Transgourmet Deutschland GmbH & Co. OHG</a></li>
                            <li><a href="https://www.kennzeichenking.de/" target="_blank" alt="KennzeichenKing.de">KennzeichenKing.de</a></li>
                            <li>DIfA Aalen</li>
                            <li><a href="https://www.fritz-stoll.de/" target="_blank" alt="Fritz Stoll Christbaumkulturen">Fritz Stoll Christbaumkulturen</a></li>
                            <li>Crowdfunderzz</li>
                            <li><a href="https://www.auenland.bio/" target="_blank" alt="Auenland.bio">Auenland.bio</a></li>
                            <li><a href="https://www.hauf-steuern.de/" target="_blank" alt="Hauf Steuerberatungsgesellschaft mbH">Hauf Steuerberatungsgesellschaft mbH</a></li>
                            <li><a href="https://innovationszentrum-aalen.de/" target="_blank" alt="Innovationszentrum Aalen">Innovationszentrum</a></li>
                            <li>Cell Garden</li>
                            <li><a href="https://www.kelterei-obele.de/" target="_blank" alt="Kelterei Obele">Kelterei Obele</a></li>
                            <li><a href="" target="_blank" alt="">Kennzeichenschmiede</a></li>
                            <li><a href="https://natur-stein-ketten.de/" target="_blank" alt="Natursteinketten Sabine Högg">Natursteinketten</a></li>
                            <li><a href="https://www.leitz.org/" target="_blank" alt="Leitz">Leitz GmbH & Co. KG</a></li>
                            <li><a href="" target="_blank" alt="">ClipCase</a></li>
                            <li><a href="https://schilderschmiede.net/" target="_blank" alt="Schilderschmiede Nördlingen">Schilderschmiede Nördlingen</a></li>
                            <li><a href="https://www.am-flugplatz-heubach.de/" target="_blank" alt="Restaurant am Flugplatz">Restaurant am Flugplatz</a></li>
                            <li><a href="https://www.rsgalgenberg.de/" target="_blank" alt="Realschule am Galgenberg">Realschule am Galgenberg</a></li>
                            <li><a href="https://www.hillerschule.de/" target="_blank" alt="Hillerschule Steinheim">Hillerschule Steinheim</a></li>
                            <li><a href="https://www.steinheim.com/" target="_blank" alt="Steinheim am Albuch">Steinheim am Albuch</a></li>
                            <li><a href="https://vaas-reinigungssysteme.de/" target="_blank" alt="Vaas Reinigungssysteme">Vaas Reinigungssysteme</a></li>
                            <li><a href="https://secrets-dessous.de/" target="_blank" alt="Secrets Dessous">Secrets Dessous</a></li>
                            <li><a href="https://lts-buehler.com/" target="_blank" alt="LTS Bühler">LTS Bühler</a></li>
                            <li>ECHTLIEB</li>
                            <li><a href="https://tekadoor.de/" target="_blank" alt="TTL Luftschleieranlagen">TTL Luftschleieranlagen</a></li>
                            <li><a href="https://www.pro-metall.com/" target="_blank" alt="ProMetall GmbH">ProMetall GmbH</a></li>
                            <li><a href="https://www.sug.de/" target="_blank" alt="S&G Automobil AG">S&G Automobil AG</a></li>
                        </ul>
                    </div>
                    <div className="experiences-content-technologies">

                        <h3>technologies</h3>
                        <ul>
                            <li>Adobe Campaign</li>
                            <li>Adobe Experience Manager</li>
                            <li>Angular</li>
                            <li>AngularJS</li>
                            <li>Bootstrap 3.3.7</li>
                            <li>Bootstrap 4.1</li>
                            <li>Bower</li>
                            <li>CSS3</li>
                            <li>Git</li>
                            <li>Greensocks / GSAP</li>
                            <li>Grunt</li>
                            <li>Gulp</li>
                            <li>Hammer.js</li>
                            <li>HTML5</li>
                            <li>SAP Hybris</li>
                            <li>JavaScript</li>
                            <li>jQuery</li>
                            <li>JSON</li>
                            <li>Jakarta Server Pages</li>
                            <li>Less</li>
                            <li>Maven</li>
                            <li>Modernizr</li>
                            <li>MorphSVG</li>
                            <li>NodeJS</li>
                            <li>npm</li>
                            <li>Nuxt.js</li>
                            <li>PHP</li>
                            <li>Pug</li>
                            <li>React Native</li>
                            <li>Sass / SCSS</li>
                            <li>SVG</li>
                            <li>Typo3</li>
                            <li>VueJS</li>
                            <li>WordPress</li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}