import React from "react";
import './style.scss';
import HeroChangingText from "../HeroChangingText";
import LogoSH from '../../assets/icons/sh.svg';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export default function Home() {
    return (
        <React.Fragment>
            <div className="home">
                <div className="home-top">
                    <h1><img src={LogoSH} className="home-logo" />stefan hergesell</h1>
                    <p>software engineer with much love for animations and design.</p>
                    <p>scored 95% in my graduation project.</p>
                    <p>professionally coding for over a decade, passionately coding since my teenage years.</p>
                    <p className="strong">currently looking for a new challenge. <NavLink to="/contact"><div className="blackbox">&gt; hire me</div></NavLink></p>
                </div>
                <div className="home-bottom">
                    <p>
                        web design <HeroChangingText /> be so much more than just information.
                    </p>
                    <p>
                        beautiful and smart designs can make the difference between a project and an experience.
                    </p>
                    <p>
                        with beautiful gradients, subtle animations and functional design <div className="blackbox">we can make it happen.</div>
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}