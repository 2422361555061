import React from "react";
import { NavLink } from "react-router-dom";
import './style.scss';



export default function Navbar() {


    return (
        <React.Fragment>
            <div className="navbar">
                <div className="navbar-content">
                        <ul>
                            <li><NavLink activeClassName="selected" to="/home">home</NavLink></li>
                            <li><NavLink activeClassName="selected" to="/experiences">experiences</NavLink></li>
                            <li><NavLink activeClassName="selected" to="/contact">contact &amp; imprint</NavLink></li>
                        </ul>
                    
                </div>
            </div>
        </React.Fragment>
    );
}