import React, { useEffect, useRef, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Navbar from './components/Navbar'
import './App.scss';
import BackgroundAnimation from "./components/BackgroundAnimation";
import Home from './components/Home';
import Experiences from './components/Experiences';
import Contact from './components/ContactImprint';

export default function App() {

  return (
    <Router>
      <BackgroundAnimation />
      <div className="border-container">
        <div className="content">
          <div className="content-border-top"></div>
          <div className="content-center">
            <div className="content-border-left"></div>
            <div className="content-center-middle">
              <div className="content-center-middle-content">
                <Switch>
                  <Route exact path="/home">
                    <Home />
                  </Route>
                  <Route path="/experiences">
                    <Experiences />
                  </Route>
                  <Route path="/contact">
                    <Contact />
                  </Route>
                  <Redirect from="/" to="/home"/>
                </Switch>
              </div>
            </div>
            <div className="content-border-right">
              <div className="content-border-right-upper"></div>
              <div className="content-border-right-center">
                <Navbar />
              </div>
              <div className="content-border-right-lower"></div>
            </div>
          </div>
          <div className="content-border-bottom"></div>
        </div>
      </div>
    </Router>
  );
}
